.button {
  font-size: $size-mid;
  font-weight: 400;
  background-color: var(--primary-light);
  color: var(--primary);
  padding: 0.75rem 1rem;
  border-radius: 12px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translateY(-3px);
    color: adjust-color(rgb(16, 185, 129), $lightness: -10%);
    background-color: rgba(213,242,232,255);
  }
}

.icon {
  margin-right: 0.75rem;
}

.pill {
  font-weight: 400;
  border-radius: 2rem;
  padding: 0.75rem;

  &.active {
    border: 3px solid var(--primary);
    color: var(--primary);
    background-color: var(--primary-light);
    cursor: pointer;

    &:hover {
      color: adjust-color(rgb(16, 185, 129), $lightness: -10%);
      background-color: rgba(213,242,232,255);
    }
  }

}

// LINKS
.link {
  text-decoration: underline dashed;
  text-decoration-skip-ink: none;
  text-underline-offset: 6px;
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    left: -0.5%;
    bottom: 10%;
    height: 4px;
    width: 0;
    background-color: var(--black);
    transition: width 0.3s ease-in-out;
  }

  &:hover::after {
    text-decoration: none;
    width: 100%;
  }
}

.link-small {
  // text-underline-offset: 8px;
  text-underline-offset: 0.45rem;
}
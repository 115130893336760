@import 'components/variables';
@import 'components/layout';
@import 'components/about_layout';
@import 'components/portfolio_item';
@import 'components/controls';
@import 'components/animations';
@import 'components/media_queries';

* {
  box-sizing: border-box;
}

.site-wrapper {
  max-width: 1000px;
  margin: 0 auto;
}

body {
  background-color: var(--greyLightBg);
  font-family: $main-font;
  color: var(--black);
  font-weight: 300;
  padding: 0 1rem;
}

a, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: none;
}
// MOBILE
@media only screen and (min-width: 600px) {

  h1 {
    font-size: $size-bigbertha;
  }

  section {
    #portfolio-container {
      grid-template-columns: 1fr;
    }

    .portfolio-item {
      &.tile-large {
        grid-template-columns: 1fr 1fr;
        align-items: center;
        padding: 2rem 0;
      }

      .tile-right {
        align-items: center;
        margin-bottom: 0;
      }
    }
  }

  #thanks-container {
    display: flex;
    flex-direction: row;

    #profile-pic {
      justify-content: center;
      align-items: center;
    }
  }
}

// SMALL LAPTOP / TABLET
@media only screen and (min-width: 601px) and (max-width: 991px){

  section {
    #portfolio-container {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);
      grid-template-columns: repeat(2, 1fr);
    }

    .portfolio-item {
      &.tile-large {
        grid-template-columns: 1fr 1fr;
        grid-column: span 2;
        padding: 0;
        align-items: center;
      }

      &.tile-large {
        grid-template-columns: 1fr 1fr;
        grid-column: span 2;
        padding: 0;
        align-items: center;
      }

      & .tile-right {
        margin: 0;
        // height: 100%;
      }
    }
  }
}

// DESKTOP SIZING
@media only screen and (min-width: 992px) {

  // INTRO SECTION
  #intro-container {

    #profile-description {
      max-width: 70%;
    }
  }

  #title-container {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    align-items: center;
  }

  // PORTFOLIO
  section {
    #portfolio-container {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(3, 1fr);
    }

    .portfolio-item {
      &.tile-large {
        grid-template-columns: 1fr 1fr;
        grid-column: span 2;
        padding: 0;
        align-items: center;
      }

      & .tile-right {
        margin: 0;
        // height: 100%;
      }
    }

    // TIMELINE SECTION
    .timeline-wrapper, #thanks-container {
      max-width: 80%;
      margin: auto;
    }
  }

  .portfolio-item.tile-large {
    grid-template-columns: 1fr 1fr;
    grid-column: span 2;
    // background-color: black;
  }

  .portfolio-item.tile-right {
    margin: 0;
  }

  // ABOUT ME PAGE
  #about-container, #me-image, #interests-container {
    width: 80%;
    margin: 4rem auto;
  }

  #swiss-screen {
    border-radius: 1rem;
  }
}


@import './variables';

// INTRO SECTION
#intro-container {
  margin: 2rem 0;
  position: relative;

  // job status pill at the top
  #current-status {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;

    &.pill {
      position: absolute;
      top: 0;
      right: 0;
      &:hover {
        color: adjust-color(rgb(16, 185, 129), $lightness: -10%);
        background-color: rgba(213,242,232,255);
      }
    }

    & #pill-emoji {
      font-size: 1.25rem;
      // padding: 0.5rem;
    }
  }

  p {
    margin: 1rem 0;
  }

  #profile-description {
    line-height: 3rem;
  }

}

.contact-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 2rem 0;
}

// PROFILE PIC
#profile-pic img {
  height: 8rem;
  border-radius: 50%;
  border: 3px dashed var(--black);
  padding: 4px;
  margin-bottom: 1rem;
}

// LET'S BOUNCE
#bounce-text {
  display: inline-flex;
}

.letter {
  animation: bounce 1s infinite;
}

#bounce-text span:nth-of-type(1) {
  animation-delay: 0.1s;
}
#bounce-text span:nth-of-type(2) {
  animation-delay: 0.2s;
}
#bounce-text span:nth-of-type(3) {
  animation-delay: 0.3s;
}
#bounce-text span:nth-of-type(4) {
  animation-delay: 0.4s;
}
#bounce-text span:nth-of-type(5) {
  animation-delay: 0.5s;
}
#bounce-text span:nth-of-type(6) {
  animation-delay: 0.6s;
}
#bounce-text span:nth-of-type(7) {
  animation-delay: 0.7s;
}
#bounce-text span:nth-of-type(8) {
  animation-delay: 0.8s;
}

// SECTION AREA
section {
  margin-top: 6rem;

  #portfolio-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .portfolio-item {
    border: 1px solid var(--greyLight);
    background-color: var(--white);
    border-radius: 25px;
    overflow: hidden;
    transition: all 0.1s ease;

    &.tile-large {
      display: grid;
      grid-template-columns: 1fr;
    }

    &.tile-small {
      display: grid;
      justify-content: center;
      margin: 0;
    }

    & .tile-centre {
      display: flex;
      flex-direction: column;
      flex: wrap;
      justify-content: center;
      padding: 2rem;
    }
    
    & .tile-right {
      display: grid;
      justify-content: end;
      align-items: center;
      margin: 0 0 2rem 0;
    }

    & .tile-left {
      display: grid;
      justify-content: start;
      align-items: center;
      margin: 0 0 2rem 0;
    }

    & .tile-vertical {
      padding: 2rem;
    }


    & #image-container {
      display: grid;
      justify-content: center;

      #battleships-screen {
        width: 100%;
        align-items: end;
      }
    }

    & #spectrum-screen {
      height: 20rem;
    }

    & #wanderlust-screen {
      height: 16rem;
      margin: 0;
      justify-content: center;
    }

    & #battleships-screen {
      padding: 0 1rem;
    }

    &:hover {
      cursor: pointer;
      outline: 3px solid var(--greyLight);
      // transform: translateY(-3px);
      box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    }
  }

  // SKILLS STYLES
  #skills-container {
    ul {
      list-style-type: none;
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      padding: 1rem;
      justify-content: center;

      li {
        display: flex;
        border: 3px solid var(--primary);
        color: var(--primary);
        font-weight: 400;
        background-color: var(--primary-light);
        border-radius: 2rem;
        padding: 0.75rem;
      }
    }
  }

  // TIMELINE STLYES
  #timeline-container {
    margin-top: 2rem;
  }

  .timeline-container {
    cursor: pointer;
    &:hover {
      color: var(--primary);
    }

    .timeline-item-name {
      display: flex;
      justify-content: space-between;
    }

    .timeline-item-description {
      margin: 1rem 0;
    }
  }

  .timelinedot {
    background-color: var(--primary);
  }

  #thanks-container {
    gap: 2rem;
    border: 1px solid var(--greyLight);
    border-radius: 25px;
    padding: 2rem 2rem 0 2rem;
    margin-bottom: 4rem;
    background-color: var(--white);

    #thanks-contact {
      display: flex;
      gap: 1rem;
    }
  }

}

footer {
  margin: 2rem 0;
  text-align: center;
  font-size: small;
}
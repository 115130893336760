#header-about {
  display: flex;
  margin-top: 3rem;
}

#about-container, #interests-container {
  line-height: 2.5rem;
}

#me-image {
  display: flex;
  justify-content: center;
  margin: 1rem -2rem;
  
  & #swiss-screen {
    width: 100%
  }
}

.interest-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  // border: 1px solid black;
  // border-radius: 15px;
  padding: 0 1rem;

  .interest-icon {
    font-size: $size-larger;
  }
}
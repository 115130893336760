@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:wght@100;300;400&display=swap');

:root {
  // primary colours
  --primary-light: rgba(52, 211, 153, .1);
  --primary: rgb(16, 185, 129);

  // supplementary colours
  --white: #FFFFFF;
  --black: rgb(24, 24, 27);
  --greyLightBg: rgb(249, 250, 251);
  --greyLight: rgb(229, 231, 235);
  --greyDark: rgb(161, 161, 170);
  --nonActive: rgb(255, 195, 0);
  --nonActiveLighter: adjust-color(rgb(255, 195, 0), $lightness: -10%);
}

// fonts
$main-font: 'Sofia Sans', sans-serif;

// sizes for fonts
$size-mid: 1.15rem;
$size-regular: 1.4rem;
$size-larger: 2rem;
$size-smallbertha: 3rem;
$size-bigbertha: 4.5rem;

// headings

h1 {
  font-size: $size-smallbertha; 
  font-weight: 400;
  margin: 0;
}

h2, .heading {
  font-size: $size-larger;
  font-weight: 400;
}

h3, .para {
  font-size: $size-regular;
  font-weight: 400;
  margin: 0;
}

.para {
  color: var(--greyDark);
  margin: 2rem 0;
}

h4 {
  font-size: $size-mid;
  color: var(--black);
  margin: 0;
  line-height: normal;
}

.accent-colour {
  color: var(--primary);
}

.black {
  color: var(--black);
}

.timeline-container {
  font-family: $main-font;
}

.tile-text {
  color: var(--greyDark);
  line-height: 1.5rem;
  font-weight: 400;
}

